
import { defineComponent, ref, onMounted, Ref, DefineComponent, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampConstrainProportionDiv
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { campHandleEmptyFields } from '@/composables/DataValidation';
import stdImgURL from "@/views/Products/assets/upload-img.png"
import { useCompanyStore } from '@/store/CompanyStore';
import { CampCropImageLoader, CampCropImageRack, CampUploadImgURL } from '@/components/CampModalCropImage';
import CampModalCropImage, { ImgResultsType } from '@/components/CampModalCropImage/CampModalCropImage.vue';

interface ICategory {
  id: number | null,
  name: string
}

interface IProductData {
  id: number,
  id_category: number,
  name: string,
  description?: string,
  points_default: number,
  sku: string,
  url_media?: string | null
  base64imgData: string | null
}

interface IProductResponse {
  data: {
    error: boolean,
    message: string,
    data: IProductData
    errorCode: string
  },
  status: number
}

interface ICampModalCropImage {}

export default defineComponent({
  name: "ProductPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampConstrainProportionDiv,
    CampCropImageLoader,
    CampCropImageRack,
    CampModalCropImage: CampModalCropImage as unknown as DefineComponent<{}, {}, ICampModalCropImage>
  },
  setup() {
    // Variables
    const loaderStore = useLoaderStore()
    const { showTimeAlert } = useAlert()
    const route = useRoute();
    const { id } = route.params;
    const productImgURL = ref(CampUploadImgURL)
    const productImgIsLoading = ref(true)
    const productImgFile = ref<File | null>(null)
    const croppedImgReady: Ref<string | null> = ref(null)
    const categoryField = ref<ICategory[] | null>(null)
    const productFields = ref<IProductData | null>(null)
    const isRequiredField = ref(false)
    const companyStore = useCompanyStore()
  
    // Functions
    function handleImgFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        productImgFile.value = file
      }
      fileInput.value = "";
    }

    function handleFileRemoval() {
      productImgIsLoading.value = false
      productImgURL.value = CampUploadImgURL
      croppedImgReady.value = null
      productFields.value!.url_media = null
    }

    function handleCroppedImgBase64(value: ImgResultsType) {
      productImgURL.value = value.croppedImgBase64
      croppedImgReady.value = value.croppedImgBase64
      // console.log("Cropped Img File: ", (value.croppedImgFile.size / 1024**2).toFixed(1) + "MB")
      // console.log("Thumbnail Img File: ", (value.thumbnailImgFile!.size / 1024**2).toFixed(1) + "MB")
    }

    async function fetchData() {
      loaderStore.open();
      try {
        const [categoryResult, productResult] = await Promise.all([
          axios.get(`/api/getCategory?id_company=${companyStore.id}`),
          axios.get(`/api/getProductView/${id}`)
        ]);

        categoryField.value = categoryResult.data.data;
        productFields.value = productResult.data.data;
        if(productFields.value?.url_media) {
          productImgURL.value = productFields.value.url_media
        }
      } catch (error) {
        if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error");
        } else {
          showTimeAlert("Algo deu errado!", "error");
        }
      } finally {
        loaderStore.close();
      }
    }

    async function onSubmitForm() {
      if (!productFields.value) {
        return;
      }
      const { id, id_category, name, sku, description, points_default, url_media } = productFields.value;
      const requiredFields = { id, id_category, name, sku, points_default }
      if (campHandleEmptyFields(requiredFields)) {
        return (isRequiredField.value = true);
      }
      loaderStore.open();
      try {
        const putData: IProductData = { ...requiredFields, description, url_media, base64imgData: croppedImgReady.value };
        const result: IProductResponse = await axios.put(`/api/putProduct`, putData);
        showTimeAlert("Registro atualizado com sucesso!");
        router.push("/produtos");
      } catch (error) {
        if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error");
        } else {
          showTimeAlert("Algo deu errado!", "error");
        }
        loaderStore.close();
      }
    }

    function getCompanyId() {
      try {
        let id: any = localStorage.getItem('cs')
        if(id) {
         id = JSON.parse(id)
        }
        companyStore.setId(id.i)
      } catch (error) {
        
      }
    }

    onBeforeMount(() => {
      getCompanyId()
    })

    // Hooks and life cycle
    onMounted(() => fetchData())

    return {
      CampUploadImgURL,
      productImgURL,
      productImgIsLoading,
      productImgFile,
      handleImgFileChange,
      handleFileRemoval,
      handleCroppedImgBase64,
      productFields,
      categoryField,
      isRequiredField,
      onSubmitForm
    }
  }
})
